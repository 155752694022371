
import { computed, defineComponent, PropType } from '@vue/composition-api'

import { useNotify } from '@/store'

import { usersApi } from '@/api'
import { UserRole, useDeleteRole } from '@/api/roles'
import { UserRight } from '@/api/rights'

export default defineComponent({
  name: 'delete-role-dialog',
  props: {
    role: {
      type: Object as PropType<UserRole<UserRight>>,
      required: true,
    },
  },
  setup: (props, { root, emit }) => {
    const { deleteRole: deleteRoleRequest, isLoading } = useDeleteRole()
    const { getUsers, paginationResponse } = usersApi.useGetUsers()

    const { addNotification } = useNotify()

    // use total elements to count the amount of users for the given role
    getUsers(
      {
        page: 0,
        size: 1,
      },
      { roles: [props.role.name] }
    )

    const usersWithRoleCount = computed(() => paginationResponse.value.totalElements)

    const deleteRole = () => {
      deleteRoleRequest(props.role.name)
        .then(() => {
          addNotification({
            type: 'success',
            text: root.$t('roles.delete.success', {
              name: props.role.name,
            }) as string,
          })
          emit('role-deleted')
          emit('close')
        })
        .catch((error) => {
          error.userMessage = root.$t('roles.delete.error', {
            name: props.role.name,
          })
          throw error
        })
    }

    return {
      deleteRole,
      usersWithRoleCount,
      isLoading,
    }
  },
})
