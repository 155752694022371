
import { Team } from '@/api/interfaces'
import { defineComponent, PropType, ref, watch } from '@vue/composition-api'
import { mdiPencil, mdiDelete } from '@mdi/js'
import { useTeamRepository } from '@/api/repository/team-repository'
import TeamDialog from '@/components/team/team-dialog.cmp.vue'
import FwFinTable from '@/components/common/fw-fin-table.cmp.vue'
import { useGetUsers, User } from '@/api/users'
import Vue from 'vue'

export default defineComponent({
  name: 'team-table',
  components: {
    FwFinTable,
    TeamDialog,
  },
  props: {
    items: {
      type: Array as PropType<Team[]>,
      required: true,
    },
    create: {
      type: Boolean,
      default: false,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { root, emit }) {
    const headers = [
      {
        text: root.$t('form.field.name').toString(),
        value: 'name',
      },
      {
        text: root.$t('form.field.teamMembers').toString(),
        value: 'teamMembers',
        sortable: false,
      },
    ]

    const teamMembersByTeams = ref<{ [key in number]: User[] }>({})

    const { getUsers, data, isLoading } = useGetUsers()

    const { dummyTeamInput } = useTeamRepository()
    const showEdit = ref(false)
    const itemToEdit = ref(dummyTeamInput())
    const showDelete = ref(false)
    const itemToDelete = ref<Team | undefined>()

    const clickEdit = async (item: Team) => {
      emit('click:edit', { ...item })
      itemToEdit.value = { ...item }
      showEdit.value = true
    }

    const clickDelete = async (item: Team) => {
      itemToDelete.value = { ...item }
      showDelete.value = true
      emit('click:delete', { ...item })
    }

    const onDeleteConfirm = async () => {
      if (!itemToDelete.value) return
      showDelete.value = false
      emit('delete', { ...itemToDelete.value })
    }

    watch(
      () => props.items,
      (teams) => {
        teams.forEach(async (team) => {
          await getUsers({ page: 0, size: 9999 }, { teamId: team.id })
          Vue.set(teamMembersByTeams.value, team.id, data.value)
        })
      }
    )

    return {
      headers,
      showEdit,
      itemToEdit,
      showDelete,
      itemToDelete,
      clickEdit,
      clickDelete,
      onDeleteConfirm,
      icons: {
        mdiPencil,
        mdiDelete,
      },
      teamMembersByTeams,
      isLoading,
    }
  },
})
