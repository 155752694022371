
import { Team, TeamInput } from '@/api/interfaces'
import { isIdentifiable } from '@/api/interfaces/identifiable'
import { useGetUsers, User } from '@/api/users'
import { required, ValidationForm } from '@/utils/validation'
import { defineComponent, PropType, ref, watch } from '@vue/composition-api'
import { uniqueId } from 'lodash-es'
import { mdiAlert } from '@mdi/js'

export default defineComponent({
  name: 'team-dialog',
  props: {
    item: {
      type: Object as PropType<TeamInput | Team>,
      required: true,
    },
  },
  setup(props, { emit, attrs }) {
    const form = ref<ValidationForm | null>(null)
    const formId = uniqueId('team-dialog-form')
    const formIsValid = ref(false)
    const team = ref<TeamInput | Team>({ ...props.item })
    const memberIds = ref<number[]>([])

    const { getUsers: getTeamMembers, data: teamMembers, isLoading: loadingTeamMembers } = useGetUsers()

    const { getUsers, data: users } = useGetUsers()

    const onCancel = () => {
      emit('input', false)
    }

    const onSubmit = () => {
      emit('input', false)
      emit('save', { ...team.value, teamMemberIds: memberIds.value })
    }

    const getFullNameFromUser = (item: User) => {
      return `${item.firstName} ${item.lastName} ${
        item.teamName && item.teamName !== props.item.name ? `(${item.teamName})` : ''
      }`
    }

    watch(
      () => props.item,
      (value) => {
        team.value = value
        teamMembers.value = []
      }
    )

    watch(
      () => attrs.value,
      async (isActive) => {
        form.value?.resetValidation
        memberIds.value = []
        if (!isActive) return
        team.value = { ...props.item }
        getUsers({ page: 0, size: 9999 })
        //get teamMembers for existing team
        if (!isIdentifiable(team.value)) return
        const teamMembers = await getTeamMembers({ page: 0, size: 9999 }, { teamId: team.value.id })
        memberIds.value = teamMembers.map((user) => user.id)
      }
    )

    return {
      isIdentifiable,
      form,
      formId,
      formIsValid,
      team,
      onCancel,
      onSubmit,
      validation: {
        required,
      },
      users,
      memberIds,
      getFullNameFromUser,
      loadingTeamMembers,
      icons: {
        mdiAlert,
      },
    }
  },
})
