
import { computed, defineComponent, onMounted, reactive, ref, watch } from '@vue/composition-api'
import ConfirmDialog from '@/components/common/confirm-dialog.vue'
import DocumentTable from '@/components/document/document-table.cmp.vue'
import DocumentUploadDialog from '@/components/document/document-upload-dialog.cmp.vue'
import { Document } from '@/api/interfaces'
import { usePagination } from '@/utils/pagination'
import { useGetUsers } from '@/api/users'
import { documentApi } from '@/api'
import { useNotify } from '@/store'
import { mdiPlus } from '@mdi/js'

export default defineComponent({
  name: 'admin-documents',
  components: {
    ConfirmDialog,
    DocumentTable,
    DocumentUploadDialog,
  },
  setup() {
    const { vuetifyTableOptions, paginationParams } = usePagination()
    vuetifyTableOptions.value.multiSort = true
    vuetifyTableOptions.value.sortBy = ['year', 'month']
    vuetifyTableOptions.value.sortDesc = [true, true]
    const items = ref<Document[]>([])
    const serverItemsLength = ref(0)
    const loading = ref(false)
    const years = computed(() => {
      const now = new Date().getUTCFullYear()
      return Array(now - 2017)
        .fill('')
        .map((v, idx) => now - idx) as Array<number>
    })
    const { getUsers, data: users } = useGetUsers()
    const filters = reactive({
      userId: null,
      year: null,
    })
    const showAddDialog = ref(false)
    const { addNotification } = useNotify()
    const showDeleteDialog = ref(false)
    const deletableDocument = ref<Document>()

    async function getDocuments() {
      loading.value = true
      documentApi.all({ params: { ...paginationParams.value, ...filters } }).then((response) => {
        items.value = response?.items || []
        serverItemsLength.value = response?.data.totalElements
        loading.value = false
      })
    }

    function clickDelete(item: Document) {
      deletableDocument.value = item
      showDeleteDialog.value = true
    }

    function deleteDocument() {
      const item = deletableDocument.value
      if (!item) return // guard that should never trigger
      documentApi.delete(item.id).then(() => {
        showDeleteDialog.value = false
        getDocuments()
        addNotification({
          text: `Deleted ${item.name}`,
          type: 'success',
        })
      })
    }

    onMounted(() => {
      getUsers({ page: 0, size: 2000 })
    })

    watch(filters, () => {
      getDocuments()
    })

    return {
      items,
      serverItemsLength,
      loading,
      years,
      getDocuments,
      vuetifyTableOptions,
      users,
      filters,
      showAddDialog,
      showDeleteDialog,
      clickDelete,
      deleteDocument,
      icons: { mdiPlus },
    }
  },
})
