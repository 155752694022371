
import { NewUserRole } from '@/api/roles'
import { useGetRights } from '@/api/rights'
import { required } from '@/utils/validation'
import { UserRight } from '@/api/rights'
import { computed, defineComponent, PropType, reactive, toRefs } from '@vue/composition-api'

export default defineComponent({
  name: 'role-form',
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object as PropType<NewUserRole>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { getRights, data: rights } = useGetRights()

    const sortedRights = computed<UserRight[]>(() =>
      rights.value
        ?.slice(0)
        .sort((a, b) => a.authority.localeCompare(b.authority))
        .map((right) => ({
          authority: right.authority,
        }))
    )

    // TODO: ugly 9999 size, find a way to get all results unpaginated
    getRights({
      size: 9999,
      page: 0,
    })

    const role = reactive(props.value)

    const updateRole = () => {
      emit('input', role)
    }

    return {
      ...toRefs(role),
      updateRole,
      sortedRights,
      required,
    }
  },
})
