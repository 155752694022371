
import { rightsApi } from '@/api'
import { debounce } from 'lodash-es'
import { DataTableHeader } from 'vuetify'
import { Rights, UserRight } from '@/api/rights'
import { useAuthGetters, useNotify } from '@/store'
import { isOfTypeWithValue } from '@/utils/isOfTypeWithValue'
import { mapRightsArrayToAuthority } from '../utils/mapRightsArrayToAuthority'
import { computed, defineComponent, ref } from '@vue/composition-api'
import { NewUserRole, UserRole, useGetRoles, useUpdateRole } from '@/api/roles'

interface RightsTableRow {
  right: string
  [key: number]: boolean
}

export default defineComponent({
  name: 'admin-rights-view',
  setup(_, { root }) {
    const { getRoles, data: roles, isLoading: rolesLoading } = useGetRoles()
    const { updateRole: updateRoleCall } = useUpdateRole()
    const { getRights, data: rights, isLoading: rightsLoading } = rightsApi.useGetRights()

    const { hasRight } = useAuthGetters()
    const hasUpdateRight = computed(() => hasRight.value(Rights.RIGHT_UPDATE))

    const { addNotification } = useNotify()

    const loading = computed(() => rolesLoading.value || rightsLoading.value)
    const isDebouncing = ref(false)

    const rolesToUpdate = new Map<string, UserRole<UserRight>>()

    const tableHeaders = ref<DataTableHeader[]>([
      {
        text: root.$tc('form.field.right', 1) as string,
        value: 'right',
      },
    ])

    const tableItems = ref<RightsTableRow[]>([])

    const loadAsyncData = async () => {
      const rightsPromise = getRights({
        page: 0,
        size: 9999,
      }).then((rights) => rights.sort((a, b) => a.authority.localeCompare(b.authority)))

      const rolesPromise = getRoles({
        page: 0,
        size: 9999,
      }).then((roles) => roles.sort((a, b) => a.name.localeCompare(b.name)))

      Promise.all([rightsPromise, rolesPromise])
        .then(([rights, roles]) => {
          // add all roles to the table headers
          tableHeaders.value.push(
            ...roles.map((role) => ({
              text: role.name,
              value: role.name,
              protected: role.protected,
              systemRole: role.systemRole,
            }))
          )

          // transform rights and roles into table items
          tableItems.value = rights.map((right) => ({
            right: right.authority,
            description: right.description,
            ...roles
              .map((role) => ({
                [role.name]: role.rights.some((roleRight) => roleRight.authority === right.authority),
              }))
              .reduce((obj, item) => ({ ...obj, ...item })),
          }))
        })
        .catch((error) => {
          error.userMessage = root.$t('roles.get.error')

          throw error
        })
    }

    loadAsyncData()

    const onRightChange = (roleName: string, rightAuthority: string, value: boolean) => {
      const role = roles.value.find((role) => role.name === roleName)
      const right = rights.value.find((right) => right.authority === rightAuthority)

      if (role && right) {
        if (!role.rights) {
          role.rights = []
        }
        const rightIndex = role.rights.findIndex((roleRight) => roleRight.authority === rightAuthority)

        if (value) {
          role.rights.push(right)
        } else {
          role.rights.splice(rightIndex, 1)
        }

        rolesToUpdate.set(role.name, role)

        isDebouncing.value = true
        updateRoles()
      }
    }

    const updateRoles = debounce(
      () => {
        const promises: Promise<UserRole<UserRight>>[] = []
        for (const role of rolesToUpdate.values()) {
          promises.push(updateRole(role))
        }
        Promise.all(promises).then(() => {
          rolesToUpdate.clear()
        })
      },
      1000,
      { leading: true }
    )

    const updateRole = async (role: UserRole<UserRight>) => {
      const mappedUserRole = mapRightsArrayToAuthority(role)

      if (isOfTypeWithValue<UserRole<string>, NewUserRole<string>>(mappedUserRole, 'editable')) {
        return await updateRoleCall(mappedUserRole)
          .then((editedRole) => {
            addNotification({
              type: 'success',
              text: root.$t('roles.update.success', {
                name: role.name,
              }) as string,
            })
            return editedRole
          })
          .catch((error) => {
            error.userMessage = root.$t('roles.update.error', {
              name: role.name,
            })

            throw error
          })
          .finally(() => {
            isDebouncing.value = false
          })
      }
      return Promise.reject(new Error('Variable mapedUserRole is not of type UserRole<string>.'))
    }

    return {
      loading,
      isDebouncing,
      hasUpdateRight,
      onRightChange,
      tableHeaders,
      tableItems,
    }
  },
})
