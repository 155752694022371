
import { useAuthGetters } from '@/store'
import AddRoleDialog from './add-role-dialog.vue'
import { usePagination } from '@/utils/pagination'
import { UserRole, useGetRoles } from '@/api/roles'
import EditRoleDialog from './edit-role-dialog.vue'
import { DataOptions, DataTableHeader } from 'vuetify'
import { IAxiosError } from '@/plugins/hateoas-client'
import DeleteRoleDialog from './delete-role-dialog.vue'
import { mdiAccountPlus, mdiPencil, mdiDelete } from '@mdi/js'
import { Rights, UserRight } from '@/api/rights'
import { defineComponent, ref, computed } from '@vue/composition-api'

export default defineComponent({
  name: 'admin-roles-view',
  components: {
    EditRoleDialog,
    AddRoleDialog,
    DeleteRoleDialog,
  },
  setup(props, { root }) {
    const initialPaginationParams: DataOptions = {
      page: 1,
      itemsPerPage: 15,
      sortBy: ['name'],
      sortDesc: [false],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: true,
    }
    const { getRoles: getRolesCall, data: roles, isLoading, paginationResponse } = useGetRoles()

    const totalRoles = computed(() => paginationResponse.value.totalElements)
    const { vuetifyTableOptions, paginationParams } = usePagination(initialPaginationParams)

    const { hasRight } = useAuthGetters()
    const hasCreateRight = computed(() => hasRight.value(Rights.ROLE_CREATE))
    const hasUpdateRight = computed(() => hasRight.value(Rights.ROLE_UPDATE))
    const hasDeleteRight = computed(() => hasRight.value(Rights.ROLE_DELETE))

    /**
     * Base Headers of the table every user that can visit this site can see
     */
    const defaultHeaders: DataTableHeader[] = [
      {
        text: root.$t('form.field.name') as string,
        value: 'name',
      },
      {
        text: root.$t('form.field.description') as string,
        value: 'description',
      },
      {
        text: root.$tc('form.field.right', 2) as string,
        value: 'rights',
        sortable: false,
      },
    ]

    /**
     * Table Headers with the Actions tab if the unser can either edit or delete
     */
    const tableHeaders = computed<DataTableHeader[]>(() =>
      defaultHeaders.concat(
        hasUpdateRight.value || hasDeleteRight.value
          ? [
              {
                text: '',
                value: 'actions',
                sortable: false,
                width: '100px',
                align: 'end',
              },
            ]
          : []
      )
    )

    const getRoles = async () => {
      try {
        getRolesCall(paginationParams.value)
      } catch (error: unknown) {
        const axiosError = error as IAxiosError
        axiosError.userMessage = root.$t('roles.get.error').toString()

        throw error
      }
    }

    // Edit Role
    const isEditActive = ref(false)
    const roleToEdit = ref<UserRole<UserRight> | null>(null)

    const openEditDialog = (role: UserRole<UserRight>) => {
      if (hasUpdateRight.value) {
        roleToEdit.value = role
        isEditActive.value = true
      }
    }

    const onRoleEdited = (editedRole: UserRole<UserRight>) => {
      const index = roles.value.findIndex((role) => role.name === editedRole.name)
      if (index >= 0) {
        root.$set(roles.value, index, editedRole)
      }
      isEditActive.value = false
    }

    // Add Role
    const isCreateActive = ref(false)

    //Delete Role
    const isDeleteActive = ref(false)
    const roleToDelete = ref<UserRole<UserRight> | null>(null)

    const openDeleteDialog = (user: UserRole<UserRight>) => {
      if (hasDeleteRight.value) {
        roleToDelete.value = user
        isDeleteActive.value = true
      }
    }

    return {
      roles,
      isLoading,
      totalRoles,
      roleToEdit,
      tableHeaders,
      roleToDelete,
      isEditActive,
      hasCreateRight,
      isDeleteActive,
      isCreateActive,
      hasUpdateRight,
      hasDeleteRight,
      vuetifyTableOptions,
      icons: { mdiAccountPlus, mdiPencil, mdiDelete },
      getRoles,
      onRoleEdited,
      openEditDialog,
      openDeleteDialog,
    }
  },
})
