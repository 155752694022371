
import { computed, defineComponent, reactive, ref } from '@vue/composition-api'

import { useNotify } from '@/store'
import { UserUpdate, useCreateUser } from '@/api/users'
import { ValidationForm } from '@/utils/validation'
import { useUpdateCurrentWorkingTimes } from '@/api/workingTime'

import { mdiCheck } from '@mdi/js'

import UserForm from './user-form.vue'

import { UserRoleNames } from '@/views/profile/profile-view.vue'

export default defineComponent({
  name: 'admin-add-user',
  components: {
    UserForm,
  },

  setup(_, { root, emit }) {
    const { createUser, isLoading } = useCreateUser()

    const { addNotification } = useNotify()

    const { updateWorkingTime } = useUpdateCurrentWorkingTimes()

    const addUserForm = ref<ValidationForm | null>(null)

    const defaultLocale = computed((): string => root.$store.state.locale.defaultLocale)

    const editUser = ref<UserUpdate>({
      enabled: true,
      locale: defaultLocale.value,
    })

    let workingTimes = reactive({
      monday: {
        begin: '09:00',
        end: '18:00',
      },
      tuesday: {
        begin: '09:00',
        end: '18:00',
      },
      wednesday: {
        begin: '09:00',
        end: '18:00',
      },
      thursday: {
        begin: '09:00',
        end: '18:00',
      },
      friday: {
        begin: '09:00',
        end: '18:00',
      },
    })

    const addUser = () => {
      if (addUserForm.value?.validate()) {
        createUser(editUser.value)
          .then((newUser) => {
            addNotification({
              type: 'success',
              text: root.$t('users.create.success', {
                name: `${newUser.firstName} ${newUser.lastName}`,
              }) as string,
            })
            emit('user-added', newUser)

            if (!newUser.roles.some((role) => role.name === UserRoleNames.Freelancer)) {
              Object.entries(workingTimes).forEach(([key, value]) =>
                updateWorkingTime(newUser.id as number, key, value.begin, value.end)
              )
            }
            emit('close')
          })
          .catch((error) => {
            error.userMessage = root.$t('users.create.error', {
              name: `${editUser.value.firstName} ${editUser.value.lastName}`,
            })
            throw error
          })
      }
    }

    return {
      icons: { mdiCheck },
      addUserForm,
      editUser,
      addUser,
      isLoading,
      workingTimes,
    }
  },
})
