
import { defineComponent, computed, watch, reactive, ref } from '@vue/composition-api'

import { meApi } from '@/api'
import { User } from '@/api/users'
import { useAuthActions, useNotify, useLocaleActions } from '@/store'
import { ValidationForm, required, email as emailRule } from '@/utils/validation'

import { mdiEmail, mdiPhone, mdiCellphone, mdiEarth, mdiCheck } from '@mdi/js'

import CommonFlagIcon from '@/components/common/common-flag-icon.vue'

import { resolveCountry } from '@/plugins/i18n'

export default defineComponent({
  name: 'profile-card',
  components: {
    CommonFlagIcon,
  },
  setup(props, { root }) {
    const { updateCurrentUser } = meApi.useUpdateCurrentUser()
    const { addNotification } = useNotify()
    const { setCurrentUser } = useAuthActions()
    const { setCurrentLocale } = useLocaleActions()

    const profileForm = ref<ValidationForm | null>(null)

    const implementedLocales = computed<string[]>(() => root.$store.state.locale.implementedLocales)

    const currentUser = computed<User | null>(() => root.$store.state.auth.currentUser)

    let editUser: User = reactive({} as User)

    watch(
      currentUser,
      (currentUser) => {
        if (currentUser) editUser = { ...currentUser }
      },
      { immediate: true, deep: true }
    )

    const editCurrentUser = async () => {
      if (profileForm.value?.validate()) {
        updateCurrentUser({ ...editUser, roles: editUser.roles.map((role) => role.name) })
          .then((user) => {
            setCurrentUser(user)

            addNotification({
              text: root.$t('profile.update.success') as string,
              type: 'success',
            })
          })
          .catch((error) => {
            error.userMessage = root.$t('profile.update.error')
            throw error
          })
      }
    }

    return {
      icons: { mdiEmail, mdiPhone, mdiCellphone, mdiEarth, mdiCheck },
      profileForm,
      required,
      emailRule,
      setCurrentLocale,
      editUser,
      implementedLocales,
      editCurrentUser,
      resolveCountry,
    }
  },
})
