
import { computed, defineComponent, PropType, ref } from '@vue/composition-api'
import { DataTableHeader } from 'vuetify'
import { mdiPencil, mdiDelete } from '@mdi/js'
import ConfirmDialog from '@/components/common/confirm-dialog.vue'

export default defineComponent({
  name: 'fwfin-table',
  components: {
    ConfirmDialog,
  },
  inheritAttrs: false,
  props: {
    headers: {
      type: Array as PropType<DataTableHeader[]>,
      default: () => [],
    },
    editable: {
      type: Function as PropType<(item: Record<string, unknown>) => boolean>,
      default: () => true,
    },
    deletable: {
      type: Function as PropType<(item: Record<string, unknown>) => boolean>,
      default: () => true,
    },
  },
  setup(props, { emit }) {
    const showDelete = ref(false)
    const itemToDelete = ref<Record<string, unknown> | undefined>()
    const tableHeaders = computed(() =>
      [...props.headers].concat({
        text: '',
        value: 'actions',
        sortable: false,
        align: 'end',
      })
    )

    const clickEdit = async (item: Record<string, unknown>) => {
      emit('click:edit', { ...item })
    }

    const clickDelete = async (item: Record<string, unknown>) => {
      itemToDelete.value = { ...item }
      showDelete.value = true
      emit('click:delete', { ...item })
    }

    const onDeleteConfirm = async () => {
      if (!itemToDelete.value) return
      showDelete.value = false
      emit('delete', { ...itemToDelete.value })
    }

    return {
      tableHeaders,
      showDelete,
      itemToDelete,
      clickEdit,
      clickDelete,
      onDeleteConfirm,
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
})
