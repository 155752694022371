
import { computed, defineComponent, PropType, reactive, toRefs } from '@vue/composition-api'
import { resolveCountry } from '@/plugins/i18n'
import { useGetRoles, UserRole } from '@/api/roles'
import { UserUpdate } from '@/api/users'
import { mdiEmail, mdiPhone, mdiCellphone, mdiEarth, mdiKey } from '@mdi/js'
import { required, email as emailRule, positiveNumber, workingTimeValidation } from '@/utils/validation'
import CommonFlagIcon from '@/components/common/common-flag-icon.vue'
import CommonNewPasswordInput from '@/components/common/common-new-password-input.vue'
import { useTeamRepository } from '@/api/repository/team-repository'
import CurrencyInput from '@/components/common/currency-input.vue'
import { UserRight } from '@/api/rights'

import { UserRoleNames } from '@/views/profile/profile-view.vue'

export default defineComponent({
  name: 'user-form',
  components: {
    CommonFlagIcon,
    CommonNewPasswordInput,
    CurrencyInput,
  },
  props: {
    value: {
      type: Object as PropType<UserUpdate>,
      required: true,
    },
    isCreate: {
      type: Boolean,
      required: false,
    },
    workingTimes: {
      type: Object,
      required: true,
    },
  },
  setup: (props, { root, emit }) => {
    const { getRoles, data: roles } = useGetRoles()
    getRoles()

    const emptyUser: UserUpdate = {
      username: '',
      email: '',
      firstName: '',
      lastName: '',
      locale: '',
      phone: '',
      mobile: '',
      enabled: false,
      roles: [],
      password: '',
      personnelNumbers: [],
      teamId: undefined,
      price: undefined,
      loginDisabled: false,
    }

    const user = reactive({ ...emptyUser, ...props.value })

    const updateUser = () => {
      emit('input', user)
    }

    const workingTimes = reactive({ ...props.workingTimes })

    const updateWorkingTimes = () => {
      emit('workingTimes', workingTimes)
    }

    const currentUserRole = computed<UserRole<UserRight>[] | undefined>(() =>
      roles.value.filter((role) => user.roles?.includes(role.name))
    )

    const implementedLocales = computed<string[]>(() => root.$store.state.locale.implementedLocales)

    const isMdAndUp = computed(() => root.$vuetify.breakpoint.mdAndUp)

    const teamRepository = useTeamRepository()
    const { getAll: getTeams, data: teams } = teamRepository.useGetAll()
    getTeams({ params: { size: 9999 } })

    return {
      icons: { mdiEmail, mdiPhone, mdiCellphone, mdiEarth, mdiKey },
      ...toRefs(user),
      user,
      updateUser,
      required,
      emailRule,
      positiveNumber,
      roles,
      implementedLocales,
      isMdAndUp,
      resolveCountry,
      teams,
      updateWorkingTimes,
      workingTimeValidation,
      currentUserRole,
      UserRoleNames,
    }
  },
})
