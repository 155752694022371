
import { defineComponent, ref, computed, reactive, watch } from '@vue/composition-api'

import { useAuthGetters, useNotify } from '@/store'
import { User, useGetUsers, useGetUsersBasic, useInvalidateToken } from '@/api/users'
import { Rights } from '@/api/rights'
import { DataTableHeader } from 'vuetify'

import { mdiAccountPlus, mdiCheck, mdiClose, mdiPencil, mdiDelete, mdiDotsVertical, mdiLock } from '@mdi/js'
import { usePagination } from '@/utils/pagination'

import EditUserDialog from './edit-user-dialog.vue'
import AddUserDialog from './add-user-dialog.vue'
import DeleteUserDialog from './delete-user-dialog.vue'
import { useGetRoles } from '@/api/roles'
import { formatCurrency } from '@/utils/format/format-currency'

export default defineComponent({
  name: 'admin-users-view',
  components: {
    AddUserDialog,
    EditUserDialog,
    DeleteUserDialog,
  },
  setup(_, { root }) {
    const { addNotification } = useNotify()

    const { getUsers: getUsersRequest, data: users, isLoading, paginationResponse } = useGetUsers()
    getUsersRequest({ page: 0, size: 15 })

    const { getUsersBasic, data: usersBasic } = useGetUsersBasic()
    getUsersBasic({ page: 0, size: 9999 })

    const { getRoles: getRolesCall, data: rolesAll } = useGetRoles()
    getRolesCall()

    const filters: { roles?: string[]; name?: string } = reactive({
      roles: undefined,
      name: undefined,
    })

    watch(filters, () => {
      updateUsers()
    })

    async function updateUsers() {
      await getUsersRequest(paginationParams.value, { ...filters })
    }

    function getUserName(user: User) {
      return `${user.firstName} ${user.lastName}`
    }

    const totalUsers = computed(() => paginationResponse.value.totalElements)

    const { vuetifyTableOptions, paginationParams } = usePagination()

    const { hasRight } = useAuthGetters()
    const hasCreateRight = computed(() => hasRight.value(Rights.USER_CREATE))
    const hasUpdateRight = computed(() => hasRight.value(Rights.USER_UPDATE))
    const hasDeleteRight = computed(() => hasRight.value(Rights.USER_DELETE))
    const currentUser = computed<User | null>(() => root.$store.state.auth.currentUser)

    function isOwnUser(id: number) {
      return currentUser.value?.id === id
    }

    /**
     * Base Headers of the table every user that can visit this site can see
     */
    const defaultHeaders: DataTableHeader[] = [
      {
        text: root.$t('form.field.id').toString(),
        value: 'id',
      },
      {
        text: root.$t('form.field.firstName').toString(),
        value: 'firstName',
      },
      {
        text: root.$t('form.field.lastName').toString(),
        value: 'lastName',
      },
      {
        text: root.$t('form.field.team').toString(),
        value: 'teamName',
        sortable: false,
      },
      {
        text: root.$t('form.field.enabled').toString(),
        value: 'enabled',
      },
      {
        text: root.$t('form.field.email').toString(),
        value: 'email',
      },
      {
        text: root.$t('form.field.personnelNumbers').toString(),
        value: 'personnelNumbers',
        sortable: false,
      },
      {
        text: root.$t('form.field.price').toString(),
        value: 'price',
      },
      {
        text: root.$t('form.field.phone') as string,
        value: 'phone',
      },
      {
        text: root.$t('form.field.mobile').toString(),
        value: 'mobile',
      },
      {
        text: root.$tc('form.field.locale', 1).toString(),
        value: 'locale',
      },
      {
        text: root.$t('form.field.role').toString(),
        value: 'roleDisplay',
        sortable: false,
      },
    ]

    /**
     * Table Headers with the Actions tab if the unser can either edit or delete
     */
    const tableHeaders = computed<DataTableHeader[]>(() =>
      defaultHeaders.concat(
        hasUpdateRight.value || hasDeleteRight.value
          ? [
              {
                text: '',
                value: 'actions',
                sortable: false,
                width: '100px',
                align: 'end',
              },
            ]
          : []
      )
    )

    const getUsers = async () => {
      try {
        getUsersRequest(paginationParams.value)
      } catch (error) {
        error.userMessage = root.$t('users.get.error')

        throw error
      }
    }

    // Edit User
    const isEditActive = ref(false)
    const userToEdit = ref<User | null>(null)

    const openEditDialog = (user: User) => {
      if (hasUpdateRight.value) {
        userToEdit.value = user
        isEditActive.value = true
      }
    }

    const onUserEdited = (editedUser: User) => {
      const index = users.value.findIndex((user) => user.id === editedUser.id)
      if (index >= 0) {
        root.$set(users.value, index, editedUser)
      }
      isEditActive.value = false
    }

    // Add User
    const isCreateActive = ref(false)

    // Delete User
    const isDeleteActive = ref(false)
    const userToDelete = ref<User | null>(null)

    const openDeleteDialog = (user: User) => {
      if (hasDeleteRight.value) {
        userToDelete.value = user
        isDeleteActive.value = true
      }
    }

    const { invalidateToken } = useInvalidateToken()

    const isTokenInvalidationOpen = ref(false)

    const userToInvalidate = ref<User | null>(null)

    function openTokenInvalidationDialog(user: User): void {
      userToInvalidate.value = user
      isTokenInvalidationOpen.value = true
    }

    function onInvalidateToken(): void {
      if (userToInvalidate.value) {
        invalidateToken(userToInvalidate.value.id).then(() => {
          isTokenInvalidationOpen.value = false
          userToInvalidate.value = null

          addNotification({
            text: root.$t('admin.users.invalidateToken.success') as string,
            type: 'success',
          })
        })
      }
    }

    return {
      icons: { mdiAccountPlus, mdiCheck, mdiClose, mdiPencil, mdiDelete, mdiDotsVertical, mdiLock },
      isOwnUser,
      hasCreateRight,
      hasUpdateRight,
      hasDeleteRight,
      tableHeaders,
      vuetifyTableOptions,
      isLoading,
      users,
      usersBasic,
      totalUsers,
      getUsers,
      isEditActive,
      userToEdit,
      openEditDialog,
      onUserEdited,
      isCreateActive,
      isDeleteActive,
      userToDelete,
      openDeleteDialog,
      isTokenInvalidationOpen,
      openTokenInvalidationDialog,
      onInvalidateToken,
      filters,
      rolesAll,
      getUserName,
      formatCurrency,
    }
  },
})
