import { useAxios, useAxiosPaginated } from '@/plugins/axios'

const version = 'v1'

export interface WorkingTimeUpdate {
  userId: number
  wordDay: string
  begin: string
  end: string
}

export const useGetCurrentWorkingTimes = () => {
  const axios = useAxiosPaginated({
    method: 'get',
    url: `${version}/workTimeSettings`,
  })

  const getCurrentWorkingTime = (userId?: number, workDay?: string) =>
    axios.exec({
      params: {
        userId,
        workDay,
        size: 9999,
      },
    })

  return {
    ...axios,
    getCurrentWorkingTime,
  }
}

export const useUpdateCurrentWorkingTimes = () => {
  const axios = useAxios<WorkingTimeUpdate>({
    method: 'post',
    url: `${version}/workTimeSettings`,
  })

  const updateWorkingTime = (userId: number, workDay: string, begin: string, end: string) =>
    axios.exec({
      data: {
        userId,
        workDay,
        begin,
        end,
      },
    })

  return {
    ...axios,
    updateWorkingTime,
  }
}
