
import { computed, defineComponent } from '@vue/composition-api'

import ProfileCard from './profile-card.vue'
import ChangePasswordCard from './change-password-card.vue'
import worktimeSettingsCard from './worktime-settings-card.vue'

export enum UserRoleNames {
  Freelancer = 'FREELANCER',
}

export default defineComponent({
  name: 'profile-view',
  components: {
    ProfileCard,
    ChangePasswordCard,
    worktimeSettingsCard,
  },
  setup(_, { root }) {
    const currentUserRoleName = computed<string[]>(() =>
      root.$store.state.auth.currentUser.roles.map((role) => role.name)
    )

    return {
      currentUserRoleName,
      UserRoleNames,
    }
  },
})
