
































































import { MonthlyHour } from '@/api/interfaces'
import { defineComponent, ref, PropType, watch } from '@vue/composition-api'
import { mdiCheck } from '@mdi/js'

export default defineComponent({
  name: 'monthly-hour-dialog',
  inheritAttrs: false,
  props: {
    item: {
      type: Object as PropType<MonthlyHour>,
      required: true,
    },
  },
  setup(props, { root }) {
    const itemInternal = ref<MonthlyHour>({ ...(props.item as MonthlyHour) })
    const monthsSelected = ref<number[]>([new Date().getMonth() + 1])

    watch(
      () => props.item,
      (value) => {
        itemInternal.value = value
        monthsSelected.value = [itemInternal.value.month]
      }
    )

    const months = [
      {
        text: root.$t('months.january'),
        value: 1,
      },
      {
        text: root.$t('months.february'),
        value: 2,
      },
      {
        text: root.$t('months.march'),
        value: 3,
      },
      {
        text: root.$t('months.april'),
        value: 4,
      },
      {
        text: root.$t('months.may'),
        value: 5,
      },
      {
        text: root.$t('months.june'),
        value: 6,
      },
      {
        text: root.$t('months.july'),
        value: 7,
      },
      {
        text: root.$t('months.august'),
        value: 8,
      },
      {
        text: root.$t('months.september'),
        value: 9,
      },
      {
        text: root.$t('months.october'),
        value: 10,
      },
      {
        text: root.$t('months.november'),
        value: 11,
      },
      {
        text: root.$t('months.december'),
        value: 12,
      },
    ]

    return {
      itemInternal,
      months,
      monthsSelected,
      icons: { mdiCheck },
    }
  },
})
