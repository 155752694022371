
import { usersApi } from '@/api'
import { mdiCheck } from '@mdi/js'
import { useNotify } from '@/store'
import RoleForm from './role-form.vue'
import { UserRight } from '@/api/rights'
import { ValidationForm } from '@/utils/validation'
import { isOfTypeWithValue } from '@/utils/isOfTypeWithValue'
import { NewUserRole, UserRole, useUpdateRole } from '@/api/roles'
import { mapRightsArrayToAuthority } from '../utils/mapRightsArrayToAuthority'
import { defineComponent, PropType, computed, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'edit-role-dialog',
  components: {
    RoleForm,
  },
  props: {
    role: {
      type: Object as PropType<UserRole<UserRight>>,
      required: true,
    },
  },
  setup: (props, { root, emit }) => {
    const { updateRole, isLoading } = useUpdateRole()
    const { getUsers, paginationResponse } = usersApi.useGetUsers()

    const { addNotification } = useNotify()

    const editRoleForm = ref<ValidationForm | null>(null)

    const roleToEdit = ref<UserRole<UserRight>>({ ...props.role })

    // use total elements to count the amount of users for the given role
    getUsers(
      {
        page: 0,
        size: 1,
      },
      { roles: [props.role.name] }
    )

    const usersWithRoleCount = computed(() => paginationResponse.value.totalElements)

    const editRole = () => {
      if (editRoleForm.value?.validate()) {
        const mapedUserRole = mapRightsArrayToAuthority(roleToEdit.value)

        if (isOfTypeWithValue<UserRole<string>, NewUserRole<string>>(mapedUserRole, 'editable')) {
          updateRole(mapedUserRole)
            .then((editedRole) => {
              addNotification({
                type: 'success',
                text: root.$t('roles.update.success', {
                  name: editedRole.name,
                }) as string,
              })
              emit('role-edited', editedRole)
              emit('close')
            })
            .catch((error) => {
              error.userMessage = root.$t('roles.update.error', {
                name: roleToEdit.value.name,
              })
              throw error
            })
        }
      }
    }

    return {
      icons: { mdiCheck },
      editRoleForm,
      roleToEdit,
      usersWithRoleCount,
      editRole,
      isLoading,
    }
  },
})
