
import { defineComponent, ref, reactive, computed } from '@vue/composition-api'

import { DataTableHeader } from 'vuetify'

import { mdiAccountPlus, mdiCheck, mdiClose, mdiPencil, mdiDelete } from '@mdi/js'
import { usePagination } from '@/utils/pagination'
import { MonthlyHour } from '@/api/interfaces'
import { monthlyHourApi } from '@/api/monthlyHour'
import { useGetUser } from '@/api/users'
import MonthlyHourDialog from './monthly-hour-dialog.vue'
import ConfirmDialog from '@/components/common/confirm-dialog.vue'

export default defineComponent({
  name: 'admin-user-hours-view',
  components: {
    ConfirmDialog,
    MonthlyHourDialog,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  setup(props, { root }) {
    const { vuetifyTableOptions, paginationParams } = usePagination()
    const showDetailDialog = ref(false)
    const showConfirmDialog = ref(false)
    const activeItem = ref<MonthlyHour>({
      id: 0,
      hours: 0,
      hoursEmploymentContract: 0,
      userId: props.userId,
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
    })

    vuetifyTableOptions.value.multiSort = true
    vuetifyTableOptions.value.sortBy = ['year', 'month']
    vuetifyTableOptions.value.sortDesc = [true, true]

    const itemToDelete = ref({})

    const tableHeaders: DataTableHeader[] = [
      {
        text: root.$t('form.field.year').toString(),
        value: 'year',
      },
      {
        text: root.$t('form.field.month').toString(),
        value: 'month',
      },
      {
        text: root.$t('form.field.hours').toString(),
        value: 'hours',
      },
      {
        text: root.$t('form.field.hoursContract').toString(),
        value: 'hoursEmploymentContract',
      },
      {
        text: '',
        value: 'actions',
        sortable: false,
        width: '100px',
        align: 'end',
      },
    ]

    const tableData = reactive({
      items: ref<MonthlyHour[]>([]),
      loading: true,
      serverItemsLength: 10,
      headers: tableHeaders,
    })

    const { getUser, data: user } = useGetUser()
    getUser(String(props.userId))

    function getHours() {
      monthlyHourApi.all({ params: { ...paginationParams.value, userId: props.userId } }).then((response) => {
        tableData.items = response?.items || []
        tableData.loading = false
        tableData.serverItemsLength = response?.data.totalElements || 0
      })
    }

    function submit(monthlyHour: MonthlyHour, months: number[]) {
      // edit
      if (monthlyHour.id !== 0) {
        monthlyHourApi.update(monthlyHour.id, monthlyHour).then(() => {
          showDetailDialog.value = false
          getHours()
        })
      } else {
        // create new
        months.forEach((month) => {
          monthlyHourApi.create({ ...monthlyHour, month }).then(() => {
            showDetailDialog.value = false
            getHours()
          })
        })
      }
    }

    function deleteMonthlyHour(item: MonthlyHour) {
      monthlyHourApi.delete(item.id).then(() => {
        showConfirmDialog.value = false
        getHours()
      })
    }

    function openDetailDialog(item: MonthlyHour) {
      activeItem.value = item
      showDetailDialog.value = true
    }

    function resetDetailDialog() {
      activeItem.value = { id: 0 } as MonthlyHour
      showDetailDialog.value = false
    }

    function openConfirmDialog(item: MonthlyHour) {
      itemToDelete.value = item
      showConfirmDialog.value = true
    }

    const breadcrumbs = computed(() => {
      return [
        {
          text: root.$t('admin.manageHours.title'),
          to: { name: 'adminManageHours' },
          exact: true,
        },
        {
          text: `${user.value?.firstName} ${user.value?.lastName}`,
        },
      ]
    })

    return {
      icons: { mdiAccountPlus, mdiCheck, mdiClose, mdiPencil, mdiDelete },
      tableHeaders,
      tableData,
      vuetifyTableOptions,
      paginationParams,
      breadcrumbs,
      getters: { getHours },
      actions: { submit, deleteMonthlyHour },
      controls: { openDetailDialog, resetDetailDialog, openConfirmDialog },
      showDetailDialog,
      showConfirmDialog,
      activeItem,
      itemToDelete,
    }
  },
})
