
import { defineComponent, ref, reactive, computed } from '@vue/composition-api'
import { ValidationForm, required, workingTimeValidation } from '@/utils/validation'
import { useGetCurrentWorkingTimes, useUpdateCurrentWorkingTimes } from '@/api/workingTime'
import { mdiCheck } from '@mdi/js'
import { useNotify } from '@/store'

export default defineComponent({
  name: 'worktime-settings-card',
  props: {},
  setup(props, { root }) {
    const currentUser = computed(() => root.$store.state.auth.currentUser)

    const { addNotification } = useNotify()

    const { getCurrentWorkingTime } = useGetCurrentWorkingTimes()

    const { updateWorkingTime } = useUpdateCurrentWorkingTimes()

    const workingTimeForm = ref<ValidationForm | null>(null)

    let workingTimes = reactive({
      monday: {
        begin: '09:00',
        end: '18:00',
      },
      tuesday: {
        begin: '09:00',
        end: '18:00',
      },
      wednesday: {
        begin: '09:00',
        end: '18:00',
      },
      thursday: {
        begin: '09:00',
        end: '18:00',
      },
      friday: {
        begin: '09:00',
        end: '18:00',
      },
    })

    getCurrentWorkingTime(currentUser.value.id).then((response) => {
      response.forEach((day) => {
        const { begin, end, workDay } = day

        workingTimes[workDay.toLowerCase()].begin = begin
        workingTimes[workDay.toLowerCase()].end = end
      })
    })

    function saveWorkingTime() {
      if (workingTimeForm.value?.validate()) {
        Object.entries(workingTimes).forEach(
          ([key, value]) => updateWorkingTime(currentUser.value.id, key, value.begin, value.end),
          addNotification({
            text: root.$t('form.save.success') as string,
            type: 'success',
          })
        )
      }
    }

    return {
      required,
      workingTimes,
      saveWorkingTime,
      workingTimeForm,
      workingTimeValidation,
      mdiCheck,
    }
  },
})
