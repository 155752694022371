
import { documentApi } from '@/api'
import { useGetUsers, User } from '@/api/users'
import { defineComponent, onMounted, ref, watch } from '@vue/composition-api'
import { DataTableHeader } from 'vuetify'
import { mdiAlert } from '@mdi/js'
import { usePagination } from '@/utils/pagination'

export default defineComponent({
  name: 'document-upload-dialog',
  inheritAttrs: false,
  setup(_, { root, emit }) {
    const tableHeaders: DataTableHeader[] = [
      {
        text: root.$t('form.field.name').toString(),
        value: 'name',
      },
      {
        text: root.$t('form.field.progress').toString(),
        value: 'progress',
      },
      {
        text: root.$t('form.field.user').toString(),
        value: 'user',
      },
    ]

    const { vuetifyTableOptions: tableOptions } = usePagination()
    tableOptions.value.itemsPerPage = -1

    enum UploadStatus {
      WAITING,
      STARTED,
      DONE,
      ERROR,
    }

    interface DocumentFile {
      name: string
      progress: number
      user?: User
      error?: string
      blob: File
      status: UploadStatus
    }

    const filesSelected = ref<File[]>([])
    const filesUpload = ref<DocumentFile[]>([])

    const { getUsers, data: users } = useGetUsers()

    function filesChanged(files: File[]) {
      if (files.length === 0) return
      files.forEach((file) => {
        // skip already added files
        if (filesUpload.value.some((f) => f.name === file.name)) return
        let { success, error } = validateFileNameFormat(file.name)
        let user: User | undefined
        let status = UploadStatus.WAITING
        if (success) {
          const { personnelNumber } = getFileNameParts(file.name)
          user = users.value.find((user) => user.personnelNumbers.some((value) => value === personnelNumber))
        } else {
          status = UploadStatus.ERROR
        }
        if (user == null) {
          status = UploadStatus.ERROR
          error = root.$t('document.error.user').toString()
        }

        filesUpload.value.push({
          name: file.name,
          progress: 0,
          user,
          error: success && user ? undefined : error,
          blob: file,
          status,
        })
      })
    }

    function getFileNameParts(name: string) {
      const filename = name.split('.')[0]
      const [year, month, rest] = filename.split('-')
      const [personnelNumber] = rest.split(' ')
      return { year, month, personnelNumber }
    }

    function validateFileNameFormat(name: string): { success: boolean; error?: string } {
      try {
        const { year, month } = getFileNameParts(name)
        if (year.length !== 4) {
          return { success: false, error: root.$t('document.error.year').toString() }
        }
        if (month.length !== 2) {
          return { success: false, error: root.$t('document.error.month').toString() }
        }
      } catch (e) {
        return { success: false, error: root.$t('document.error.format').toString() }
      }
      return { success: true }
    }

    async function uploadFile(file: DocumentFile) {
      const { year, month } = getFileNameParts(file.name)
      if (file.user == null) return
      file.status = UploadStatus.STARTED
      documentApi
        .uploadFile(
          { file: file.blob, year: Number(year), month: Number(month), userId: file.user.id },
          {
            onUploadProgress: function (event) {
              const progress = (event.loaded * 100) / event.total
              file.progress = progress
            },
          }
        )
        .then(() => {
          file.status = UploadStatus.DONE
        })
        .catch(() => {
          file.status = UploadStatus.ERROR
          file.error = root.$t('document.upload.error.general').toString()
        })
    }

    async function reset() {
      filesUpload.value = []
    }

    async function onCancel() {
      emit('cancel')
      emit('input', false)
      reset()
    }

    onMounted(() => {
      getUsers({ page: 0, size: 2000 })
    })

    watch(filesSelected, (value) => {
      filesChanged(value)
      filesSelected.value.length = 0
    })

    watch(
      filesUpload,
      (value) => {
        // check if upload in progress
        if (value.some((file) => file.status === UploadStatus.STARTED)) return
        const nextFile = value.find((file) => file.status === UploadStatus.WAITING)
        if (nextFile) uploadFile(nextFile)
        else emit('success')
      },
      { deep: true }
    )

    return {
      tableHeaders,
      tableOptions,
      filesSelected,
      filesUpload,
      icons: { mdiAlert },
      onCancel,
    }
  },
})
