
import { computed, defineComponent, PropType, reactive, ref } from '@vue/composition-api'
import { User, UserUpdate, useUpdateUser } from '@/api/users'
import { useNotify, useLocaleActions } from '@/store'
import { ValidationForm } from '@/utils/validation'
import { mdiCheck } from '@mdi/js'
import UserForm from './user-form.vue'
import { useGetCurrentWorkingTimes, useUpdateCurrentWorkingTimes } from '@/api/workingTime'
import { useGetRoles, UserRole } from '@/api/roles'
import { UserRight } from '@/api/rights'
import { UserRoleNames } from '@/views/profile/profile-view.vue'

export default defineComponent({
  name: 'admin-user-detail',
  components: {
    UserForm,
  },
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },
  setup: (props, { root, emit }) => {
    const { addNotification } = useNotify()
    const { updateUser, isLoading } = useUpdateUser()

    const { setCurrentLocale } = useLocaleActions()

    const { updateWorkingTime } = useUpdateCurrentWorkingTimes()
    const { getCurrentWorkingTime } = useGetCurrentWorkingTimes()

    const currentUser: User = root.$store.state.auth.currentUser

    const editUserForm = ref<ValidationForm | null>(null)

    const userToEdit = ref<UserUpdate>({
      ...props.user,
      roles: props.user.roles.map((role) => role.name),
    })

    const { getRoles, data: roles } = useGetRoles()
    getRoles()

    const currentUserRole = computed<UserRole<UserRight>[] | undefined>(() =>
      roles.value.filter((role) => userToEdit.value.roles?.includes(role.name))
    )

    let workingTimes = reactive({
      monday: {
        begin: '09:00',
        end: '18:00',
      },
      tuesday: {
        begin: '09:00',
        end: '18:00',
      },
      wednesday: {
        begin: '09:00',
        end: '18:00',
      },
      thursday: {
        begin: '09:00',
        end: '18:00',
      },
      friday: {
        begin: '09:00',
        end: '18:00',
      },
    })

    getCurrentWorkingTime(props.user.id).then((response) => {
      response.forEach((day) => {
        const { begin, end, workDay } = day

        workingTimes[workDay.toLowerCase()].begin = begin
        workingTimes[workDay.toLowerCase()].end = end
      })
    })

    const editUser = () => {
      if (editUserForm.value?.validate()) {
        if (currentUser.id === props.user.id) setCurrentLocale(props.user.locale)

        const userFullName = `${userToEdit.value.firstName} ${userToEdit.value.lastName}`

        updateUser(props.user.id as number, { ...userToEdit.value })
          .then((editedUser) => {
            addNotification({
              type: 'success',
              text: root.$t('users.update.success', {
                name: userFullName,
              }) as string,
            })
            emit('user-edited', editedUser)
          })
          .catch((error) => {
            error.userMessage = root.$t('users.update.error', {
              name: userFullName,
            })
            throw error
          })
        if (currentUserRole.value && !currentUserRole.value?.some((role) => role.name === UserRoleNames.Freelancer)) {
          Object.entries(workingTimes).forEach(([key, value]) =>
            updateWorkingTime(props.user.id as number, key, value.begin, value.end)
          )
        }
      }
    }

    return {
      icons: { mdiCheck },
      editUserForm,
      userToEdit,
      editUser,
      isLoading,
      workingTimes,
    }
  },
})
