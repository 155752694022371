var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-card-title',{staticClass:"text-h4"},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs}}),_c('v-spacer'),_c('monthly-hour-dialog',{attrs:{"item":_vm.activeItem},on:{"submit":_vm.actions.submit,"close":function($event){return _vm.controls.resetDetailDialog()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","elevation":"0"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiAccountPlus))]),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.$t('form.action.add'))+" ")])],1)]}}]),model:{value:(_vm.showDetailDialog),callback:function ($$v) {_vm.showDetailDialog=$$v},expression:"showDetailDialog"}})],1),_c('v-card-text',{staticClass:"pt-4"},[_c('v-data-table',_vm._b({attrs:{"options":_vm.vuetifyTableOptions},on:{"update:options":[function($event){_vm.vuetifyTableOptions=$event},function($event){return _vm.getters.getHours()}]},scopedSlots:_vm._u([{key:"item.complete",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(_vm.getters.userHasCompleteHours(item) ? _vm.icons.mdiCheck : _vm.icons.mdiClose))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.controls.openDetailDialog(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.controls.openConfirmDialog(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])]}}],null,true)},'v-data-table',_vm.tableData,false))],1)],1),_c('confirm-dialog',{attrs:{"item":_vm.itemToDelete},on:{"close":function($event){_vm.showConfirmDialog = false},"confirm":_vm.actions.deleteMonthlyHour},model:{value:(_vm.showConfirmDialog),callback:function ($$v) {_vm.showConfirmDialog=$$v},expression:"showConfirmDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }