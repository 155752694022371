import { UserRight } from '@/api/rights'
import { NewUserRole, UserRole } from '@/api/roles'

function mapRightsArrayToAuthority(
  userRole: UserRole<UserRight> | NewUserRole<UserRight>
): UserRole<string> | NewUserRole<string> {
  return {
    ...userRole,
    rights: userRole.rights.map((right) => right.authority),
  }
}

export { mapRightsArrayToAuthority }
