
import { useTeamRepository } from '@/api/repository/team-repository'
import { usePagination } from '@/utils/pagination'
import { defineComponent, onMounted, reactive, ref, watch } from '@vue/composition-api'
import TeamDialog from '@/components/team/team-dialog.cmp.vue'
import TeamTable from '@/components/team/team-table.cmp.vue'
import { debounce } from 'lodash'
import { useAuthGetters, useNotify } from '@/store'
import { Rights } from '@/api/rights'
import { mdiPlus } from '@mdi/js'
import { Team, TeamInput } from '@/api/interfaces'

export default defineComponent({
  name: 'team-overview',
  components: {
    TeamDialog,
    TeamTable,
  },
  setup(_, { root }) {
    const { addNotification } = useNotify()
    const teamRepository = useTeamRepository()
    const { getAll, data: items, isLoading, total } = teamRepository.useGetAll()
    const { getAll: getAllTeams, data: nameSearchItems, isLoading: nameSearchIsLoading } = teamRepository.useGetAll()
    const { vuetifyTableOptions, paginationParams } = usePagination()
    const { hasRight } = useAuthGetters()

    const editIsActive = ref(false)
    const itemToEdit = ref<TeamInput>({ ...teamRepository.dummyTeamInput() })

    const filters = reactive({
      name: '',
    })

    const nameSearch = ref('')

    function fetchTableItems() {
      getAll({ params: { ...filters, ...paginationParams.value } })
    }

    const debouncefetchNameSearchItems = debounce((name) => {
      getAllTeams({ params: { name } })
    }, 300)

    const canCreateTeam = () => {
      return hasRight.value(Rights.TEAM_CREATE)
    }

    const canUpdateTeam = () => {
      return hasRight.value(Rights.TEAM_UPDATE)
    }

    const canDeleteTeam = (team: Team) => {
      return hasRight.value(Rights.TEAM_DELETE) && team.size === 0
    }

    const onSave = async (item: TeamInput) => {
      const { save } = teamRepository.useSave()
      await save(item)
      itemToEdit.value = { ...teamRepository.dummyTeamInput() }
      addNotification({ text: root.$t('form.save.success').toString(), type: 'success' })
      fetchTableItems()
    }

    const onDelete = async (item: Team) => {
      const { remove } = teamRepository.useRemove()
      await remove(item.id)
      addNotification({ text: root.$t('form.save.success').toString(), type: 'success' })
      fetchTableItems()
    }

    watch(
      filters,
      () => {
        fetchTableItems()
      },
      {
        immediate: true,
      }
    )

    watch(nameSearch, (name) => {
      debouncefetchNameSearchItems(name)
    })

    onMounted(() => {
      getAllTeams({ params: { name: nameSearch.value } })
    })

    return {
      items,
      isLoading,
      total,
      filters,
      fetchTableItems,
      vuetifyTableOptions,
      nameSearch,
      nameSearchItems,
      nameSearchIsLoading,
      canCreateTeam,
      canUpdateTeam,
      canDeleteTeam,
      icons: {
        mdiPlus,
      },
      editIsActive,
      itemToEdit,
      onSave,
      onDelete,
    }
  },
})
