
import { mdiCheck } from '@mdi/js'
import { useNotify } from '@/store'
import RoleForm from './role-form.vue'
import { UserRight } from '@/api/rights'
import { ValidationForm } from '@/utils/validation'
import { defineComponent, ref } from '@vue/composition-api'
import { isOfTypeWithValue } from '@/utils/isOfTypeWithValue'
import { NewUserRole, UserRole, useCreateRole } from '@/api/roles'
import { mapRightsArrayToAuthority } from '../utils/mapRightsArrayToAuthority'

export default defineComponent({
  name: 'add-role-dialog',
  components: {
    RoleForm,
  },
  setup(props, { root, emit }) {
    const { createRole, isLoading } = useCreateRole()

    const { addNotification } = useNotify()

    const addRoleForm = ref<ValidationForm | null>(null)

    const editRole = ref<NewUserRole<UserRight>>({
      name: '',
      description: '',
      rights: [],
    })

    const addRole = () => {
      if (addRoleForm.value?.validate()) {
        const mappedUserRole = mapRightsArrayToAuthority(editRole.value)

        if (!isOfTypeWithValue<UserRole<string>, NewUserRole<string>>(mappedUserRole, 'editable')) {
          createRole(mappedUserRole)
            .then((newRole) => {
              addNotification({
                type: 'success',
                text: root.$t('roles.create.success', {
                  name: newRole.name,
                }) as string,
              })
              emit('role-added', newRole)
              emit('close')
            })
            .catch((error) => {
              error.userMessage = root.$t('roles.create.error', {
                name: editRole.value.name,
              })
              throw error
            })
        }
      }
    }

    return {
      icons: { mdiCheck },
      addRoleForm,
      editRole,
      addRole,
      isLoading,
    }
  },
})
