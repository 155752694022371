
import { defineComponent, ref, computed, reactive, watch } from '@vue/composition-api'

import { User, useGetUsers, useGetUsersBasic } from '@/api/users'
import { DataTableHeader } from 'vuetify'

import { mdiAccountPlus, mdiCheck, mdiClose, mdiPencil, mdiDelete } from '@mdi/js'
import { usePagination } from '@/utils/pagination'
import { MonthlyHour } from '@/api/interfaces'
import { monthlyHourApi } from '@/api/monthlyHour'

export default defineComponent({
  name: 'admin-monthly-hours-view',

  setup(props, { root }) {
    const { getUsers: getUsersRequest, data: users, paginationResponse } = useGetUsers()

    const { getUsersBasic, data: usersBasic } = useGetUsersBasic()

    getUsersBasic({ page: 0, size: 9999 }, { active: true })

    const totalUsers = computed(() => paginationResponse.value.totalElements)

    const { vuetifyTableOptions, paginationParams } = usePagination()

    const monthlyHours = ref<MonthlyHour[]>([])

    const userIds = computed(() => users.value.map((user) => user.id))

    /**
     * Base Headers of the table every user that can visit this site can see
     */
    const tableHeaders: DataTableHeader[] = [
      {
        text: root.$t('form.field.id').toString(),
        value: 'id',
      },
      {
        text: root.$t('form.field.firstName').toString(),
        value: 'firstName',
      },
      {
        text: root.$t('form.field.lastName').toString(),
        value: 'lastName',
      },
      {
        text: root.$t('form.field.complete').toString(),
        value: 'complete',
        sortable: false,
      },
    ]

    const tableData = reactive({
      items: ref<User[]>([]),
      loading: true,
      serverItemsLength: totalUsers,
      headers: tableHeaders,
    })

    const filters: { name?: string } = reactive({
      name: undefined,
    })

    function getUserName(user: User) {
      return `${user.firstName} ${user.lastName}`
    }

    watch(filters, () => {
      getUsers()
    })

    function getUsers() {
      getUsersRequest(paginationParams.value, { ...filters }).then((response) => {
        tableData.items = response
        tableData.loading = false
        monthlyHourApi.all({ params: { userIds: userIds.value.join(','), size: 2000 } }).then((response) => {
          monthlyHours.value = response?.items || []
        })
      })
    }

    function userHasCompleteHours(user: User) {
      const currentYear = new Date().getFullYear()
      const hours = monthlyHours.value.filter((hour) => hour.userId === user.id && hour.year === currentYear)
      return hours.length === 12
    }

    return {
      icons: { mdiAccountPlus, mdiCheck, mdiClose, mdiPencil, mdiDelete },
      vuetifyTableOptions,
      getUsers,
      usersBasic,
      getUserName,
      filters,
      tableData,
      monthlyHours,
      getters: { userHasCompleteHours },
    }
  },
})
